import React, { useContext, useState } from "react";
import { BoxArrowInLeft, QuestionCircle } from "react-bootstrap-icons";
import { Nav, Navbar, NavbarBrand } from "reactstrap";
import { roxyAdventBeApiServiceContext } from "../../../api";
import logo from "../../../assets/logo-length.svg";
import { HelpComponent } from "../help-component";
import { NavigationEntry } from "./navigation-entry";

interface IHeaderProps {}

export const Header: React.FC<IHeaderProps> = () => {
  const { fireBaseApp } = useContext(roxyAdventBeApiServiceContext);
  const [helpOpen, setHelpOpen] = useState<boolean>(false);

  const openHelp = () => {
    setHelpOpen(!helpOpen);
  };

  const logout = () => {
    fireBaseApp.auth().signOut();
  };

  return (
    <>
      <Navbar color="light" className="d-flex justify-content-center">
        <NavbarBrand className="roxy-advent-header">
          <img src={logo} alt="logo" />
        </NavbarBrand>
        <Nav className="ml-auto">
          <NavigationEntry pointer={true} icon={<BoxArrowInLeft />}>
            <div onClick={logout}>Uslogge</div>
          </NavigationEntry>
          <NavigationEntry pointer={true} icon={<QuestionCircle />}>
            <div onClick={openHelp}>Hilfe</div>
          </NavigationEntry>
        </Nav>
      </Navbar>
      <HelpComponent isOpen={helpOpen} toggle={setHelpOpen} />
    </>
  );
};
