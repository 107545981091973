import { useContext } from "react";
import { useMutation, UseMutationResult } from "react-query";
import { roxyAdventBeApiServiceContext } from "../../../api-service-context";
import { ISurprise } from "../../domain";
import { QueryKeyEntityType } from "../../query-keys";

interface IUseUpdateSurpriseParams {
  id: string;
  params: Partial<ISurprise>;
}

export const useUpdateSurprise = (): UseMutationResult<
  void,
  Error,
  IUseUpdateSurpriseParams
> => {
  const { roxyAdventBeApi } = useContext(roxyAdventBeApiServiceContext);
  const updateSurprise = (params: IUseUpdateSurpriseParams) =>
    roxyAdventBeApi.updateSurprise(params.id, params.params);

  return useMutation(
    QueryKeyEntityType.ROXY_ADVENT_UPDATE_SURPRISES,
    updateSurprise
  );
};
