import React from "react";

interface ISnow {}

export const Snow: React.FC<ISnow> = () => {
  return (
    <div className="roxy-advent-background-controller__snow-wrapper">
      {Array.from(Array(149).keys()).map((flake) => (
        <div
          data-testid="snow"
          key={flake}
          className="roxy-advent-background-controller__snow"
        />
      ))}
    </div>
  );
};
