import {
  AppShell as AppShellCore,
  Content,
  Header,
  Footer,
  Container,
} from "./app-shell";

export const AppShell = { AppShell: AppShellCore, Content, Header, Footer };

export { Container };

export * from "./app-shell-context";
