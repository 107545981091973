import React, { useState } from "react";
import { AttachmentType, IContent } from "../../../api";
import { Carousel } from "react-responsive-carousel";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal as BootStrapModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { PlyrPlayer } from "../plyr-video-player/plyr-video-player";

interface IModalProps {
  isOpen: boolean;
  toggle?: () => void;
  content: IContent;
}

export const Modal: React.FC<IModalProps> = ({ isOpen, toggle, content }) => {
  const { attachments } = content;
  const { auxilaryAttachments, displayAttachment } = attachments;
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const auxilaryPicturesVideos = auxilaryAttachments.filter(
    (attachment) =>
      attachment.type === AttachmentType.PICTURE ||
      attachment.type === AttachmentType.VIDEO
  );
  const auxilaryFiles = auxilaryAttachments.filter(
    (attachment) => attachment.type === AttachmentType.FILE
  );

  switch (displayAttachment.type) {
    case AttachmentType.PICTURE || AttachmentType.VIDEO:
      auxilaryPicturesVideos.push(displayAttachment);
      break;
    case AttachmentType.FILE:
      auxilaryFiles.push(displayAttachment);
      break;
  }

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <BootStrapModal centered={true} size={"md"} isOpen={isOpen} toggle={toggle}>
      <ModalHeader>{content.title}</ModalHeader>
      <ModalBody className="roxy-advent-modal__body">
        {auxilaryPicturesVideos.length !== 0 && (
          <Carousel
            autoPlay={false}
            showStatus={false}
            showThumbs={false}
            dynamicHeight={true}
            interval={2000000000}
            showIndicators={
              auxilaryPicturesVideos.length > 1 &&
              auxilaryAttachments.filter(
                (media) => media.type !== AttachmentType.PICTURE
              ).length === 0
            }
            showArrows={auxilaryPicturesVideos.length > 1}
          >
            {auxilaryPicturesVideos.map((media) => {
              if (media.type === AttachmentType.PICTURE) {
                return (
                  <div key={media.urlToResource}>
                    <img src={media.urlToResource} alt="wohoo" />
                  </div>
                );
              } else {
                return (
                  <PlyrPlayer
                    className="roxy-advent-modal__plyr"
                    src={media.urlToResource}
                  />
                );
              }
            })}
          </Carousel>
        )}

        <div className="roxy-advent-modal_body--content">{content.text}</div>
      </ModalBody>
      <ModalFooter>
        {auxilaryFiles.length === 1 && (
          <Button
            href={auxilaryFiles[0].urlToResource}
            tag="a"
            target="_blank"
            color="primary"
          >
            {auxilaryFiles[0].name}
          </Button>
        )}
        {auxilaryFiles.length > 1 && (
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle color="primary" caret={true}>
              Gschänklis :)
            </DropdownToggle>
            <DropdownMenu>
              {auxilaryFiles.map((file) => (
                <DropdownItem
                  key={file.urlToResource}
                  href={file.urlToResource}
                  tag="a"
                  target="_blank"
                >
                  {file.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        )}
        <Button color="link" onClick={toggle}>
          Zue mache
        </Button>
      </ModalFooter>
    </BootStrapModal>
  );
};
