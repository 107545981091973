import { getMonth } from "date-fns";
import React, { useEffect } from "react";
import { Snow } from "./snow";

interface IBackgroundControllerProps {}

export enum BackgroundClasses {
  OFF_SEASON = "roxy-advent-background-controller__out-of-season",
  OCTOBER = "roxy-advent-background-controller__october",
  NOVEMBER = "roxy-advent-background-controller__november",
  DECEMBER = "roxy-advent-background-controller__december",
}

export const mapBackgroundToMonth = (): string => {
  switch (getMonth(Date.now())) {
    case 9:
      return BackgroundClasses.OCTOBER;
    case 10:
      return BackgroundClasses.NOVEMBER;
    case 11:
      return BackgroundClasses.DECEMBER;
    default:
      return BackgroundClasses.OFF_SEASON;
  }
};

export const BackgroundController: React.FC<IBackgroundControllerProps> =
  () => {
    useEffect(() => {
      document
        .getElementsByTagName("html")[0]
        .setAttribute("class", mapBackgroundToMonth());
    });

    return <>{getMonth(Date.now()) === 11 && <Snow />}</>;
  };
