import { createContext, useContext, useEffect, useState } from "react";
import { roxyAdventBeApiServiceContext } from "../../../api";
import { Spinner } from "../spinner";

export interface AppShellContext {
  currentUser?: any;
}

export const appShellContext = createContext<AppShellContext>({});

export interface IAppShellContextProviderProps {
  value: AppShellContext;
}

export const AppShellContextProvider: React.FC<IAppShellContextProviderProps> =
  ({ children, value }) => {
    const { fireBaseApp } = useContext(roxyAdventBeApiServiceContext);
    const [currentUser, setCurrentUser] = useState();
    const [pending, setPending] = useState(true);

    useEffect(() => {
      fireBaseApp.auth().onAuthStateChanged((user: any) => {
        setCurrentUser(user);
        setPending(false);
      });
    }, [fireBaseApp]);

    if (pending) {
      return <Spinner />;
    }

    return (
      <appShellContext.Provider value={{ ...value, currentUser }}>
        {children}
      </appShellContext.Provider>
    );
  };
