/* eslint-disable @typescript-eslint/naming-convention */

export enum PlyrControls {
    playLarge = 'play-large',
    play = 'play',
    progress = 'progress',
    currentTime = 'current-time',
    mute = 'mute',
    volume = 'volume',
    captions = 'captions',
    settings = 'settings',
    pip = 'pip',
    airplay = 'airplay',
    fullscreen = 'fullscreen',
}

export enum PlyrSettings {
    captions = 'captions',
    quality = 'quality',
    speed = 'speed',
    loop = 'loop',
}
