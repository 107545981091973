import { AttachmentType, ISurprise } from "../../domain";

export const fetchSurprises: ISurprise[] = [
  {
    id: "1WF5dV4WEieqMXS3TCVK",
    date: { _seconds: 1638313200, _nanoseconds: 0 },
    content: {
      text: "TBD",
      title: "TBD",
      attachments: {
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/1_the_grimoire_of_sorcery/display.jpg",
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            type: AttachmentType.FILE,
            name: "TBD",
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/1_the_grimoire_of_sorcery/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
      },
    },
    open: true,
  },
  {
    id: "6O3m0kogtezTC2ZQgUlb",
    open: true,
    content: {
      title: "TBD",
      text: "TBD",
      attachments: {
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/2_the_testament_of_xoacoatli/display.jpg",
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            type: AttachmentType.FILE,
            name: "TBD",
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/2_the_testament_of_xoacoatli/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
      },
    },
    date: { _seconds: 1638399600, _nanoseconds: 0 },
  },
  {
    id: "9CqEYM7F8ExN0AeFnJSn",
    open: true,
    content: {
      attachments: {
        displayAttachment: {
          urlToResource:
            "https://quino.ch/roxy/app/3_the_ythoth_slates/display.jpg",
          type: AttachmentType.PICTURE,
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            name: "TBD",
            type: AttachmentType.FILE,
          },
          {
            type: AttachmentType.PICTURE,
            urlToResource:
              "https://quino.ch/roxy/app/3_the_ythoth_slates/1.jpg",
          },
        ],
      },
      text: "TBD",
      title: "TBD",
    },
    date: { _seconds: 1638486000, _nanoseconds: 0 },
  },
  {
    id: "Al8jcAOPFr2o1dp6LWcd",
    date: { _seconds: 1638572400, _nanoseconds: 0 },
    open: true,
    content: {
      attachments: {
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/4_the_testament_of_aloch/display.jpg",
        },
        auxilaryAttachments: [
          {
            name: "TBD",
            type: AttachmentType.FILE,
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/4_the_testament_of_aloch/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
      },
      text: "TBD",
      title: "TBD",
    },
  },
  {
    id: "BAlsD5UwePlv1lQM4lYp",
    content: {
      title: "TBD",
      text: "TBD",
      attachments: {
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/5_the_tome_of_cataclysm/display.jpg",
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            name: "TBD",
            type: AttachmentType.FILE,
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/5_the_tome_of_cataclysm/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
      },
    },
    open: true,
    date: { _seconds: 1638658800, _nanoseconds: 0 },
  },
  {
    id: "BxbhzN4C8Npe8dc0GSkp",
    date: { _seconds: 1638745200, _nanoseconds: 0 },
    content: {
      title: "TBD",
      attachments: {
        displayAttachment: {
          urlToResource:
            "https://quino.ch/roxy/app/6_the_book_of_worms/display.jpg",
          type: AttachmentType.PICTURE,
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            type: AttachmentType.FILE,
            name: "TBD",
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/6_the_book_of_worms/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
      },
      text: "TBD",
    },
    open: true,
  },
  {
    id: "DG35OHERH9RNvyCE3qYu",
    content: {
      title: "TBD",
      attachments: {
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/7_the_compendium_of_al-ud/display.jpg",
        },
        auxilaryAttachments: [
          {
            type: AttachmentType.FILE,
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            name: "TBD",
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/7_the_compendium_of_al-ud/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
      },
      text: "TBD",
    },
    date: { _seconds: 1638831600, _nanoseconds: 0 },
    open: true,
  },
  {
    id: "FFtXesMzEkOoRzoj4PRz",
    open: true,
    date: { _seconds: 1638918000, _nanoseconds: 0 },
    content: {
      title: "TBD",
      text: "TBD",
      attachments: {
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            name: "TBD",
            type: AttachmentType.FILE,
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/8_the_zanthu_tablets/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/8_the_zanthu_tablets/display.jpg",
        },
      },
    },
  },
  {
    id: "M74T1O0h1HgOB8i0fiz4",
    date: { _seconds: 1639004400, _nanoseconds: 0 },
    content: {
      text: "TBD",
      attachments: {
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/9_the_sameski_fragments/display.jpg",
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            name: "TBD",
            type: AttachmentType.FILE,
          },
          {
            type: AttachmentType.PICTURE,
            urlToResource:
              "https://quino.ch/roxy/app/9_the_sameski_fragments/1.jpg",
          },
        ],
      },
      title: "TBD",
    },
    open: true,
  },
  {
    id: "PrUpiVeuc4s9qh4RQPmC",
    content: {
      text: "TBD",
      attachments: {
        displayAttachment: {
          urlToResource:
            "https://quino.ch/roxy/app/10_the_book_of_shadows/display.jpg",
          type: AttachmentType.PICTURE,
        },
        auxilaryAttachments: [
          {
            type: AttachmentType.FILE,
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            name: "TBD",
          },
          {
            type: AttachmentType.PICTURE,
            urlToResource:
              "https://quino.ch/roxy/app/10_the_book_of_shadows/1.jpg",
          },
        ],
      },
      title: "TBD",
    },
    open: true,
    date: { _seconds: 1639090800, _nanoseconds: 0 },
  },
  {
    id: "TWArpCKF8H55wZQnKwra",
    content: {
      title: "TBD",
      text: "TBD",
      attachments: {
        auxilaryAttachments: [
          {
            name: "TBD",
            type: AttachmentType.FILE,
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/11_the_chants_of_angum/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
        displayAttachment: {
          urlToResource:
            "https://quino.ch/roxy/app/11_the_chants_of_angum/display.jpg",
          type: AttachmentType.PICTURE,
        },
      },
    },
    date: { _seconds: 1639177200, _nanoseconds: 0 },
    open: true,
  },
  {
    id: "Uy3UdwFpt4RFKKM50w18",
    content: {
      text: "TBD",
      title: "TBD",
      attachments: {
        auxilaryAttachments: [
          {
            type: AttachmentType.FILE,
            name: "TBD",
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          },
          {
            type: AttachmentType.PICTURE,
            urlToResource:
              "https://quino.ch/roxy/app/12_the_testament_of_aldis/1.jpg",
          },
        ],
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/12_the_testament_of_aldis/display.jpg",
        },
      },
    },
    open: true,
    date: { _seconds: 1639263600, _nanoseconds: 0 },
  },
  {
    id: "WQ4lP82CPDPCpBn5JJDB",
    content: {
      title: "TBD",
      attachments: {
        auxilaryAttachments: [
          {
            name: "TBD",
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            type: AttachmentType.FILE,
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/13_the_voormoga_apocrypha/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
        displayAttachment: {
          urlToResource:
            "https://quino.ch/roxy/app/13_the_voormoga_apocrypha/display.jpg",
          type: AttachmentType.PICTURE,
        },
      },
      text: "TBD",
    },
    date: { _seconds: 1639350000, _nanoseconds: 0 },
    open: true,
  },
  {
    id: "aLZ2y7K8IzVmw4AtcL6P",
    date: { _seconds: 1639436400, _nanoseconds: 0 },
    content: {
      text: "TBD",
      title: "TBD",
      attachments: {
        displayAttachment: {
          urlToResource:
            "https://quino.ch/roxy/app/14_the_chants_of_alchiatzin/display.jpg",
          type: AttachmentType.PICTURE,
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            name: "TBD",
            type: AttachmentType.FILE,
          },
          {
            type: AttachmentType.PICTURE,
            urlToResource:
              "https://quino.ch/roxy/app/14_the_chants_of_alchiatzin/1.jpg",
          },
        ],
      },
    },
    open: true,
  },
  {
    id: "acH4907zS7FG7fSi4PdF",
    content: {
      attachments: {
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            name: "TBD",
            type: AttachmentType.FILE,
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/15_the_revelations_of_dere/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
        displayAttachment: {
          urlToResource:
            "https://quino.ch/roxy/app/15_the_revelations_of_dere/display.jpg",
          type: AttachmentType.PICTURE,
        },
      },
      title: "TBD",
      text: "TBD",
    },
    date: { _seconds: 1639522800, _nanoseconds: 0 },
    open: true,
  },
  {
    id: "gSP16vK1nu4QkYWjt5Yf",
    content: {
      attachments: {
        displayAttachment: {
          urlToResource:
            "https://quino.ch/roxy/app/16_the_testament_of_sinmuzi/display.jpg",
          type: AttachmentType.PICTURE,
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            name: "TBD",
            type: AttachmentType.FILE,
          },
          {
            type: AttachmentType.PICTURE,
            urlToResource:
              "https://quino.ch/roxy/app/16_the_testament_of_sinmuzi/1.jpg",
          },
        ],
      },
      text: "TBD",
      title: "TBD",
    },
    date: { _seconds: 1639609200, _nanoseconds: 0 },
    open: true,
  },
  {
    id: "hAJPVoIh6PsZxL0d6ULb",
    date: { _seconds: 1639695600, _nanoseconds: 0 },
    content: {
      text: "TBD",
      attachments: {
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/17_the_leaves_of_archel/display.jpg",
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            type: AttachmentType.FILE,
            name: "TBD",
          },
          {
            type: AttachmentType.PICTURE,
            urlToResource:
              "https://quino.ch/roxy/app/17_the_leaves_of_archel/1.jpg",
          },
        ],
      },
      title: "TBD",
    },
    open: true,
  },
  {
    id: "hCWjgYUJHqZCcSRTvoTX",
    open: true,
    content: {
      text: "TBD",
      title: "TBD",
      attachments: {
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/18_the_compendium_of_alugar/display.jpg",
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            type: AttachmentType.FILE,
            name: "TBD",
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/18_the_compendium_of_alugar/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
      },
    },
    date: { _seconds: 1639782000, _nanoseconds: 0 },
  },
  {
    id: "iZlYBRkvqVaam9kIaXMV",
    date: { _seconds: 1639868400, _nanoseconds: 0 },
    open: true,
    content: {
      attachments: {
        displayAttachment: {
          urlToResource:
            "https://quino.ch/roxy/app/19_the_apadar_incunabulum/display.jpg",
          type: AttachmentType.PICTURE,
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            type: AttachmentType.FILE,
            name: "TBD",
          },
          {
            type: AttachmentType.PICTURE,
            urlToResource:
              "https://quino.ch/roxy/app/19_the_apadar_incunabulum/1.jpg",
          },
        ],
      },
      title: "TBD",
      text: "TBD",
    },
  },
  {
    id: "jumOtpjUY9kjTox2N0g9",
    date: { _seconds: 1639954800, _nanoseconds: 0 },
    content: {
      text: "TBD",
      attachments: {
        displayAttachment: {
          urlToResource:
            "https://quino.ch/roxy/app/20_the_bha-cykran_fragments/display.jpg",
          type: AttachmentType.PICTURE,
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            name: "TBD",
            type: AttachmentType.FILE,
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/20_the_bha-cykran_fragments/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
      },
      title: "TBD",
    },
    open: true,
  },
  {
    id: "lrGdF6zIcKJMbr7VgWi7",
    open: true,
    date: { _seconds: 1640041200, _nanoseconds: 0 },
    content: {
      attachments: {
        displayAttachment: {
          urlToResource:
            "https://quino.ch/roxy/app/21_the_folio_of_thari/display.jpg",
          type: AttachmentType.PICTURE,
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            name: "TBD",
            type: AttachmentType.FILE,
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/21_the_folio_of_thari/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
      },
      text: "TBD",
      title: "TBD",
    },
  },
  {
    id: "mkZlbz6QK5LDKgui2BID",
    content: {
      title: "TBD",
      text: "TBD",
      attachments: {
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/22_the_revelations_of_sorcery/display.jpg",
        },
        auxilaryAttachments: [
          {
            type: AttachmentType.FILE,
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            name: "TBD",
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/22_the_revelations_of_sorcery/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
      },
    },
    open: true,
    date: { _seconds: 1640127600, _nanoseconds: 0 },
  },
  {
    id: "wIV8tO9g0lj6wd8N3FNN",
    open: true,
    content: {
      title: "TBD",
      text: "TBD",
      attachments: {
        auxilaryAttachments: [
          {
            name: "TBD",
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            type: AttachmentType.FILE,
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/23_the_baalilu_shards/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/23_the_baalilu_shards/display.jpg",
        },
      },
    },
    date: { _seconds: 1640214000, _nanoseconds: 0 },
  },
  {
    id: "x43xCPqCGgJrjZYtmW5M",
    open: true,
    date: { _seconds: 1640300400, _nanoseconds: 0 },
    content: {
      title: "TBD",
      attachments: {
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            name: "TBD",
            type: AttachmentType.FILE,
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/24_the_libram_of_aman/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/24_the_libram_of_aman/display.jpg",
        },
      },
      text: "TBD",
    },
  },
  {
    id: "zsBI8XCowCTIYGjQFPPx",
    open: true,
    date: { _seconds: 1640386800, _nanoseconds: 0 },
    content: {
      title: "TBD",
      text: "TBD",
      attachments: {
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/25_the_astub_tablets/display.jpg",
        },
        auxilaryAttachments: [
          {
            name: "TBD",
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            type: AttachmentType.FILE,
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/25_the_astub_tablets/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
      },
    },
  },
];
