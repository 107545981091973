import React from "react";
import { fireBaseApp } from "./roxy-advent-be-api/firebase";
import { RoxyAdventBeApi } from "./roxy-advent-be-api/roxy-advent-be-api";

export interface IRoxyAdventBeApiServiceContextProps {
  roxyAdventBeApi: RoxyAdventBeApi;
  fireBaseApp: any;
}

const createApiInstance = () => {
  if (process.env.NODE_ENV === "development") {
    return new RoxyAdventBeApi();
  }

  return new RoxyAdventBeApi({
    baseURL: "https://us-central1-roxy-advent.cloudfunctions.net/api",
  });
};

export const roxyAdventBeApiServiceContext =
  React.createContext<IRoxyAdventBeApiServiceContextProps>({
    roxyAdventBeApi: createApiInstance(),
    fireBaseApp: fireBaseApp,
  });
