import classNames from "classnames";
import React from "react";

interface INavigationEntryProps {
  icon?: React.ReactNode;
  pointer?: boolean;
}

export const NavigationEntry: React.FC<INavigationEntryProps> = ({
  children,
  icon,
  pointer,
}) => {
  return (
    <div
      className={classNames("roxy-advent-navigation-entry", {
        pointer: pointer,
      })}
    >
      {icon && <div className="roxy-advent-navigation-entry__icon">{icon}</div>}
      <div className="roxy-advent-navigation-entry__label">{children}</div>
    </div>
  );
};
