import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import { generatePath } from "react-router";
import { IEvent, ISurprise } from "./domain";
import { fireBaseApp } from "./firebase";
import { fetchSurprises, fetchEvents } from "./mocks";

export enum Url {
  EVENTS = "event",
  SURPRISE = "surprise",
  UPDATE_SURPRISE = "surprise/:id",
  UPDATE_EVENT = "event/:id",
}

export class RoxyAdventBeApi {
  protected readonly http: AxiosInstance;

  constructor(defaultRequestConfig: AxiosRequestConfig = { baseURL: "/api" }) {
    let headers = defaultRequestConfig.headers ?? {};
    // If no explicit content type is provided we want to use 'application/json' because this is what 99% of our APIs expect. For the remaining 1%,
    // please make sure to provide an explicit header value.
    /* istanbul ignore else */
    if (headers["Content-Type"] == null) {
      headers = {
        ...headers,
        "Content-Type": "application/json",
      };
    }

    this.http = axios.create({
      ...defaultRequestConfig,
      headers,
      baseURL: `${defaultRequestConfig.baseURL ?? ""}`
        // Removes double slashes
        .replace(/\/{2,}/g, "/")
        .replace(/^(https?):\/(?!\/)/, "$1://"),
    });
  }

  async fetchEvents(): Promise<IEvent[]> {
    if (process.env.NODE_ENV === "development") {
      return Promise.resolve(fetchEvents);
    }

    const token = await fireBaseApp.auth().currentUser?.getIdToken();

    const { data } = await this.http.get<IEvent[]>(Url.EVENTS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  }

  async fetchSurprises(): Promise<ISurprise[]> {
    if (process.env.NODE_ENV === "development") {
      return Promise.resolve(fetchSurprises);
    }

    const token = await fireBaseApp.auth().currentUser?.getIdToken();

    const { data } = await this.http.get<ISurprise[]>(Url.SURPRISE, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  }

  async updateSurprise(id: string, params: Partial<ISurprise>): Promise<void> {
    const token = await fireBaseApp.auth().currentUser?.getIdToken();

    await this.http.put<ISurprise>(
      generatePath(Url.UPDATE_SURPRISE, { id }),
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  async updateEvent(id: string, params: Partial<IEvent>): Promise<void> {
    const token = await fireBaseApp.auth().currentUser?.getIdToken();

    await this.http.put<IEvent>(
      generatePath(Url.UPDATE_EVENT, { id }),
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}
