import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useUpdateSurprise } from "../../../api";
import { useSurprises } from "../../../api/roxy-advent-be-api/hooks/queries/use-surprises";
import { Modal } from "../../components/modal";
import { CommonPath } from "../../utils";

interface ISurprisePageProps {}

export const SurprisePage: React.FC<ISurprisePageProps> = () => {
  const [open, setOpen] = useState<boolean>(true);

  const history = useHistory();
  const { data: surprises } = useSurprises();
  const { params } = useRouteMatch<{ surpriseId: string }>();
  const { mutate: setSurpriseOpen } = useUpdateSurprise();
  const { surpriseId } = params;

  const isSurpriseOpen = surprises?.find(
    (surprise) => surprise.id === surpriseId
  )?.open;
  const surpriseContent = surprises?.find(
    (surprise) => surprise.id === surpriseId
  )?.content;

  const toggle = () => {
    setOpen(!open);
    history.push(CommonPath.CALENDAR);
    if (!isSurpriseOpen) {
      setSurpriseOpen({ id: surpriseId, params: { open: true } });
    }
  };

  const surpriseDate = surprises?.find((surprise) => surprise.id === surpriseId)
    ?.date._seconds;

  if (surpriseDate && new Date(Date.now()) < new Date(surpriseDate * 1000)) {
    return null;
  }

  return (
    <div className="roxy-advent-event-page">
      {surpriseContent && (
        <Modal content={surpriseContent} isOpen={open} toggle={toggle} />
      )}
    </div>
  );
};
