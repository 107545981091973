import { formatISO, differenceInDays } from "date-fns";
import { useState } from "react";

const getInstallPromptLastSeenAt = (promptName: string): string =>
  localStorage.getItem(promptName) ?? "";

const setInstallPromptSeenToday = (promptName: string): void => {
  const today = formatISO(Date.now());
  localStorage.setItem(promptName, today);
};

function getUserShouldBePromptedToInstall(
  promptName: string,
  daysToWaitBeforePromptingAgain: number
): boolean {
  const lastPrompt = new Date(getInstallPromptLastSeenAt(promptName));
  const daysSinceLastPrompt = differenceInDays(Date.now(), lastPrompt);

  return (
    isNaN(daysSinceLastPrompt) ||
    daysSinceLastPrompt > daysToWaitBeforePromptingAgain
  );
}

const useShouldShowPrompt = (
  promptName: string,
  daysToWaitBeforePromptingAgain = 1
): [boolean, () => void] => {
  const [userShouldBePromptedToInstall, setUserShouldBePromptedToInstall] =
    useState(
      getUserShouldBePromptedToInstall(
        promptName,
        daysToWaitBeforePromptingAgain
      )
    );

  const handleUserSeeingInstallPrompt = () => {
    setUserShouldBePromptedToInstall(false);
    setInstallPromptSeenToday(promptName);
  };

  return [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};
export default useShouldShowPrompt;
