import React, { memo, useMemo } from "react";
import Masonry from "react-masonry-css";
import { Spinner } from "../spinner";
import { useSurprises } from "../../../api/roxy-advent-be-api/hooks/queries/use-surprises";
import { CalendarItem, CalendarItemType } from "../calendar-item";
import { useEvents } from "../../../api";

interface ICalendarProps {}

const breakpointColumnsObj = {
  default: 4,
  1400: 3,
  900: 2,
  500: 1,
};

export const Calendar: React.FC<ICalendarProps> = memo(() => {
  const { data: surprises, isLoading: isLoadingSurprises } = useSurprises();
  const { data: events, isLoading: isLoadingEvents } = useEvents();

  const surpriseEntries: React.ReactNode[] | undefined = useMemo(
    () =>
      surprises?.map((surprise) => (
        <CalendarItem
          type={CalendarItemType.SURPRISE}
          key={surprise.id}
          surprise={surprise}
        />
      )),
    [surprises]
  );

  const eventEntries: React.ReactNode[] | undefined = useMemo(
    () =>
      events?.map((surprise) => (
        <CalendarItem
          type={CalendarItemType.EVENT}
          key={surprise.id}
          surprise={surprise}
        />
      )),
    [events]
  );

  const shuffledEntries: React.ReactNode[] | undefined = useMemo(() => {
    const shuffledArray: React.ReactNode[] = [];
    if (surpriseEntries && eventEntries) {
      const cadency = Math.floor(
        surpriseEntries?.length / eventEntries?.length
      );
      let counter = 0;
      surpriseEntries.forEach((surprise, i) => {
        if (i % cadency === 0 && eventEntries.length > counter) {
          shuffledArray.push(eventEntries[counter]);
          shuffledArray.push(surprise);
          counter++;
          return;
        }
        shuffledArray.push(surprise);
      });
    }
    return shuffledArray;
  }, [eventEntries, surpriseEntries]);

  const isLoading = isLoadingEvents || isLoadingSurprises;

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="roxy-advent-calendar">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="roxy-advent-masonry-grid"
            columnClassName="roxy-advent-masonry-grid_column"
          >
            {shuffledEntries}
          </Masonry>
        </div>
      )}
    </>
  );
});
