import { useContext } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { roxyAdventBeApiServiceContext } from "../../../api-service-context";
import { IEvent } from "../../domain";
import { QueryKeyEntityType } from "../../query-keys";

export const useSurprises = (): UseQueryResult<IEvent[], Error> => {
  const { roxyAdventBeApi } = useContext(roxyAdventBeApiServiceContext);
  const fetchSurprises = () => roxyAdventBeApi.fetchSurprises();

  return useQuery(QueryKeyEntityType.ROXY_ADVENT_SURPRISES, fetchSurprises, {
    refetchOnWindowFocus: false,
  });
};
