import { IAttachmentDefinition } from "../../api";

export const defineAttachment = (attachments: IAttachmentDefinition[]) => {
  if (Array.isArray(attachments)) {
    return attachments.map((a) => {
      return a.urlToResource;
    });
  }

  return null;
};

export const defineDisplayAttachment = (attachment: IAttachmentDefinition) => {
  return attachment.urlToResource !== "" ? attachment.urlToResource : null;
};
