import { useContext } from "react";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { roxyAdventBeApiServiceContext } from "../../../api-service-context";
import { IEvent } from "../../domain";
import { QueryKeyEntityType } from "../../query-keys";

interface IUseUpdateEventParams {
  id: string;
  params: Partial<IEvent>;
}

export const useUpdateEvent = (): UseMutationResult<
  void,
  Error,
  IUseUpdateEventParams
> => {
  const { roxyAdventBeApi } = useContext(roxyAdventBeApiServiceContext);
  const queryClient = useQueryClient();
  const updateEvent = (params: IUseUpdateEventParams) =>
    roxyAdventBeApi.updateEvent(params.id, params.params);

  return useMutation(
    QueryKeyEntityType.ROXY_ADVENT_UPDATE_EVENTS,
    updateEvent,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeyEntityType.ROXY_ADVENT_EVENTS);
      },
    }
  );
};
