import React from "react";
import spinner from "../../../assets/roxy-advent-loading.svg";

interface ISpinnerProps {}

export const Spinner: React.FC<ISpinnerProps> = () => {
  return (
    <div className="roxy-advent-spinner">
      <img src={spinner} alt="loading" />
    </div>
  );
};
