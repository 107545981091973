import React, { useContext, useEffect, useState } from "react";
import { Toast as BootstrapToast, ToastBody } from "reactstrap";
import { appShellContext } from "../app-shell";

interface IToastProps {
  id?: string;
  className?: string;
  title: string;
  text: string;
  showToast: boolean;
  openLink: () => void;
}

export const Toast: React.FC<IToastProps> = ({
  children,
  className,
  showToast,
  openLink,
}) => {
  const [show, setShow] = useState<boolean>(showToast);
  const { currentUser } = useContext(appShellContext);

  const toggle = () => {
    setShow(false);
    openLink();
  };

  useEffect(() => {
    setShow(showToast);
  }, [showToast]);

  if (!!!currentUser) {
    return null;
  }

  return (
    <BootstrapToast
      className="roxy-advent-toast"
      onClick={toggle}
      isOpen={show}
    >
      <ToastBody className={className}>{children}</ToastBody>
    </BootstrapToast>
  );
};
