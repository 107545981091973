import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { CalendarPage } from "./app/pages/calendar-page";
import { Route, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { QueryClient, QueryClientProvider } from "react-query";
import { CommonPath } from "./app/utils";
import { LoginPage } from "./app/pages/login-page";
import { BackgroundController } from "./app/components/background-controller";
import { AppShell } from "./app/components/app-shell";
import { Header } from "./app/components/header";
import { DevBackgroundSwitcher } from "./app/components/background-switcher/background-switcher";
import { EventsPage } from "./app/pages/events-page";
import { PrivateRoute } from "./app/utils/private-route";
import { SurprisePage } from "./app/pages/surprise-page";
import { InstallPwa } from "./app/components/install-pwa";

const history = createBrowserHistory();

const queryClient = new QueryClient();

ReactDOM.render(
  <React.Fragment>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <AppShell.AppShell>
          <InstallPwa />
          {process.env.NODE_ENV !== "development" ? (
            <BackgroundController />
          ) : (
            <DevBackgroundSwitcher />
          )}
          <AppShell.Header>
            <Header />
          </AppShell.Header>
          <AppShell.Content>
            <Route path={CommonPath.ROOT} exact={true} component={LoginPage} />
            <PrivateRoute
              path={CommonPath.SURPRISE}
              exact={true}
              component={SurprisePage}
            />
            <PrivateRoute
              path={CommonPath.EVENT}
              exact={true}
              component={EventsPage}
            />
            <PrivateRoute path={CommonPath.CALENDAR} component={CalendarPage} />
          </AppShell.Content>
          <AppShell.Footer></AppShell.Footer>
        </AppShell.AppShell>
      </Router>
    </QueryClientProvider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
