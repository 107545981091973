import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useEvents } from "../../../api";
import { useUpdateEvent } from "../../../api/roxy-advent-be-api/hooks/mutations/update-event";
import { Modal } from "../modal";

interface IEventController {}

export const EventController: React.FC<IEventController> = () => {
  const { data: events } = useEvents();
  const [modals, setModals] = useState<string[]>();
  const { mutate: setEventOpen } = useUpdateEvent();

  const toggle = (eventId: string) => {
    setEventOpen({ id: eventId, params: { open: true } });
    setModals(modals?.filter((event) => eventId !== event));
  };

  useEffect(() => {
    setModals(
      events
        ?.filter(
          (event) =>
            !event.open &&
            format(event.date._seconds * 1000, "MM/dd/yyyy") ===
              format(Date.now(), "MM/dd/yyyy")
        )
        .map((event) => {
          return event.id;
        })
    );
  }, [events]);

  const eventModals = events
    ?.filter((event) => !event.open)
    .map(
      (event) =>
        event.content && (
          <Modal
            key={event.id}
            content={event.content}
            isOpen={modals?.includes(event.id)!!}
            toggle={() => toggle(event.id)}
          />
        )
    );

  return <>{eventModals}</>;
};
