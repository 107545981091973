import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useEvents } from "../../../api";
import { useUpdateEvent } from "../../../api/roxy-advent-be-api/hooks/mutations/update-event";
import { Modal } from "../../components/modal";
import { CommonPath } from "../../utils";

export const EventsPage: React.FC = () => {
  const [open, setOpen] = useState<boolean>(true);

  const history = useHistory();
  const { data: events } = useEvents();
  const { params } = useRouteMatch<{ eventId: string }>();
  const { mutate: setEventOpen } = useUpdateEvent();
  const { eventId } = params;

  const isEventOpen = events?.find((event) => event.id === eventId)?.open;
  const eventContent = events?.find((event) => event.id === eventId)?.content;

  const toggle = () => {
    setOpen(!open);
    history.replace(CommonPath.CALENDAR);
    if (!isEventOpen) {
      setEventOpen({ id: eventId, params: { open: true } });
    }
  };

  const eventDate = events?.find((event) => event.id === eventId)?.date
    ._seconds;

  if (eventDate && new Date(Date.now()) < new Date(eventDate * 1000)) {
    return null;
  }

  return (
    <div className="roxy-advent-event-page">
      {eventContent && (
        <Modal content={eventContent} isOpen={open} toggle={toggle} />
      )}
    </div>
  );
};
