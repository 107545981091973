import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import addToHomeScreen from "../../../assets/icons/add-to-homescreen.svg";
import { Files } from "react-bootstrap-icons";

interface IHelpComponentProps {
  toggle: (open: boolean) => void;
  isOpen: boolean;
}

export const HelpComponent: React.FC<IHelpComponentProps> = ({
  toggle,
  isOpen,
}) => {
  const setOpen = () => {
    toggle(!isOpen);
  };

  return (
    <Modal centered={true} size={"md"} isOpen={isOpen} toggle={setOpen}>
      <ModalHeader>Da zum klarheit schaffe</ModalHeader>
      <ModalBody className="roxy-advent-help-page">
        <div>
          <p>
            Hallo REBELLIN,
            <br />
            <br />
            Ich han mer müeh geh d'App so eifach und verständlich wie möglich
            z'gstalte, aber zum sicher sii das alles klar isch, gits die
            sektion, wo hoffentli alli frage beantworte chan.
          </p>
          <h2 className="h6">Generells</h2>
          <p>
            Ja es isch chli früeh für en Adventskaländer 😂...aber genau will
            Wiehnachte bzw. Dezember no so wiet weg isch(!!!), gits d' "Special
            days". Grundsätzlich unterscheidet sich die nöd grossartig vo de
            Adventstäg nur das die scho früenner ufgmacht werde chönd. Wenn dass
            die ufmache chasch isch allerdings nöd bekannt 🤷🏻‍♂️. Ab und zue
            ineluege lohnt sich aber sicher au vor wiehnachte 😊. Übrigens die
            Special days chönd sich au vermehre uuund mer munklet das de
            Osterhaas es paar Eastereggs versteckt hät 😁. Findsch sie?
          </p>
          <h2 className="h6">Hiiwies</h2>
          <p>
            Es törli cha mehreri Bilder und Videos enthalte. Wenn also obe
            rechts ufem vorschau-bild das <Files /> zeiche gsehsch, chasch{" "}
            <b>wenns törli in vollahsicht häsch</b> dur d'bilder und videos
            swipe (oder klicke, ufem bild ganz links bzw. ganz rechts).
          </p>
          <h2 className="h5">Installation</h2>
          <p>Du häsch d'möglichkeit d'App z'installiere!</p>
          <h2 className="h6">iOS</h2>
          <p>
            Zum das uf iOS mache, chasch du unde eifach uf
            <img
              src={addToHomeScreen}
              style={{ margin: "auto 8px 8px" }}
              className=""
              alt="Add to homescreen"
              width="12"
            />
            tappe und "Add to Home-Screen" uswähle.
          </p>
          <h2 className="h6">Android</h2>
          <p>
            Im Chrome eifach s'menü obe ufmache (das mit de 3 pünkt) und denn uf
            "Install App" clicke.
          </p>
          <h2 className="h5">Zum Schluss</h2>
          <p>
            Hoffe das alles womer erchläre muess jetzt erchlärt isch und das
            freud drah häsch, a de app sowie a de überraschige 😊. Wenn
            allefalls öbis nöd klar isch, weisch wod mich kontaktiere chasch
            😊👋🏼 <br />
            <br />
            Viel Spass <br />
            Giusi
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={setOpen}>
          Oki...alles klar
        </Button>
      </ModalFooter>
    </Modal>
  );
};
