import React, { useContext, useState } from "react";
import { Button, Card, CardBody, Form, Input, Jumbotron } from "reactstrap";
import { appShellContext, Container } from "../../components/app-shell";
import logo from "../../../assets/logo.svg";
import { roxyAdventBeApiServiceContext } from "../../../api";
import { Redirect } from "react-router";
import { CommonPath } from "../../utils";

interface ILoginPageProps {}

export const LoginPage: React.FC<ILoginPageProps> = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { fireBaseApp } = useContext(roxyAdventBeApiServiceContext);
  const { currentUser } = useContext(appShellContext);

  const login = async (event: any) => {
    event.preventDefault();
    try {
      await fireBaseApp.auth().signInWithEmailAndPassword(username, password);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {!!currentUser ? (
        <Redirect to={CommonPath.CALENDAR} />
      ) : (
        <div className="roxy-advent-login-page">
          <Container>
            <div className="roxy-advent-login-page__login-jumbo">
              <Jumbotron>
                <Card>
                  <div className="roxy-advent-login-page__login-wave">
                    <img src={logo} alt="logo" />
                  </div>
                  <CardBody>
                    Hoi und wilkomme uf dinnere eigete app. Zum meh gseh, log
                    dich doch eifach ih ;)...
                    <Form onSubmit={login}>
                      <div className="roxy-advent-login-page__form">
                        <Input
                          size={4}
                          type="text"
                          name="username"
                          id="username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          placeholder="Din Name"
                        />
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Dis Passwort"
                        />
                        <Button color="primary" type="submit">
                          Ihlogge
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Jumbotron>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};
