import React, { useEffect, useState } from "react";
import className from "classnames";
import {
  AttachmentType,
  IContent,
  IEvent,
  ITimestamp,
  useUpdateSurprise,
} from "../../../api";
import { format, getDate } from "date-fns";
import { defineDisplayAttachment } from "../../utils";
import { Button } from "reactstrap";
import { Modal } from "../modal";
import classNames from "classnames";
import { useUpdateEvent } from "../../../api/roxy-advent-be-api/hooks/mutations/update-event";
import confetti from "../../../assets/icons/confetti.gif";
import { Files } from "react-bootstrap-icons";

interface ICalendarItemDoor {
  label?: string;
  open: boolean;
  openDoor: () => void;
  date: ITimestamp;
}

const CalendarItemDoor: React.FC<ICalendarItemDoor> = ({
  open,
  date,
  openDoor,
  label,
}) => {
  return (
    <div
      data-testid="roxy-advent-calendar-item-door"
      className={className("roxy-advent-calendar-item__door", {
        "roxy-advent-calendar-item__door-open": open,
        "roxy-advent-calendar-item__door-open-possible":
          format(date._seconds * 1000, "MM/dd/yyyy") <=
            format(Date.now(), "MM/dd/yyyy") && !open,
      })}
      onClick={openDoor}
    >
      {open ? (
        ""
      ) : label ? (
        <h1>{label}</h1>
      ) : (
        <div style={{ display: "grid" }}>
          <img
            src={confetti}
            alt="Special Day"
            width="100px"
            style={{ justifySelf: "center", marginBottom: "1rem" }}
          />
          <h4>{"Special day :)"}</h4>
        </div>
      )}
    </div>
  );
};

interface ICalendarContent {
  content: IContent;
  toggle: () => void;
  modal: boolean;
}

const CalendarContent: React.FC<ICalendarContent> = ({
  content,
  toggle,
  modal,
}) => {
  const imgSrc = defineDisplayAttachment(content.attachments.displayAttachment);

  return (
    <div className="roxy-advent-calendar-item-surprise">
      <div className="roxy-advent-calendar-item-surprise__title">
        <h1 className="h6">{content.title}</h1>
      </div>
      <div className="roxy-advent-calendar-item-surprise__header">
        {content?.attachments.auxilaryAttachments.filter(
          (a) => a.type !== AttachmentType.FILE
        ).length >= 1 && (
          <span className="roxy-advent-calendar-item-surprise__header__multi">
            <Files />
          </span>
        )}

        {imgSrc && <img alt="img" src={imgSrc} />}
      </div>
      <div className="roxy-advent-calendar-item-surprise__text">
        <p>
          {content?.text.length > 250
            ? `${content?.text.substr(0, 150)}...`
            : content?.text}
        </p>
      </div>
      <div className="roxy-advent-calendar-item-surprise__actions">
        <Button color="primary" size="sm" onClick={toggle}>
          Ufmache
        </Button>
        <Modal content={content} toggle={toggle} isOpen={modal} />
      </div>
    </div>
  );
};

export enum CalendarItemType {
  SURPRISE = "surpise",
  EVENT = "event",
}
interface ICalendarItemProps {
  surprise: IEvent;
  type: CalendarItemType;
}

export const CalendarItem: React.FC<ICalendarItemProps> = ({
  surprise,
  type,
}) => {
  const [open, setOpen] = useState<boolean>(surprise.open);
  const [modal, setModal] = useState<boolean>(false);
  const { mutate: setSurpriseOpen } = useUpdateSurprise();
  const { mutate: setEventOpen } = useUpdateEvent();
  const isEvent = !!surprise.title;

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    setOpen(surprise.open);
  }, [surprise.open]);

  const openDoor = () => {
    if (
      format(surprise.date._seconds * 1000, "MM/dd/yyyy") <=
      format(Date.now(), "MM/dd/yyyy")
    ) {
      setOpen(true);
      if (isEvent) {
        setEventOpen({ id: surprise.id, params: { open: true } });
      } else {
        setSurpriseOpen({ id: surprise.id, params: { open: true } });
      }
    }
  };

  return (
    <div
      className={classNames(
        "roxy-advent-calendar-item",
        {
          closed:
            !open &&
            !(
              format(surprise.date._seconds * 1000, "MM/dd/yyyy") <=
              format(Date.now(), "MM/dd/yyyy")
            ),
        },
        type
      )}
    >
      {surprise.content && (
        <CalendarContent
          toggle={toggle}
          modal={modal}
          content={surprise.content}
        />
      )}
      <CalendarItemDoor
        open={open}
        date={surprise.date}
        openDoor={openDoor}
        label={
          !isEvent
            ? getDate(surprise.date._seconds * 1000).toString()
            : undefined
        }
      />
    </div>
  );
};
