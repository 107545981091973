import { AttachmentType, IEvent } from "../../domain";

export const fetchEvents: IEvent[] = [
  {
    id: "CRvcPLht6Vm81TRtUzJ2",
    title: "TBD",
    date: { _seconds: 1629756000, _nanoseconds: 0 },
    open: true,
    content: {
      title: "TBD",
      attachments: {
        auxilaryAttachments: [
          {
            name: "TBD",
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            type: AttachmentType.FILE,
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/2s_the_sathl-shulhoa_incunabulum/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/2s_the_sathl-shulhoa_incunabulum/display.jpg",
        },
      },
      text: "TBD",
    },
  },
  {
    id: "CUG5Ao5D8ebMlYMvNbch",
    title: "TBD",
    content: {
      text: "TBD",
      attachments: {
        auxilaryAttachments: [
          {
            name: "TBD",
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            type: AttachmentType.FILE,
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/3s_the_stone_of_cybele/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
        displayAttachment: {
          urlToResource:
            "https://quino.ch/roxy/app/3s_the_stone_of_cybele/display.jpg",
          type: AttachmentType.PICTURE,
        },
      },
      title: "TBD",
    },
    open: true,
    date: { _seconds: 1629237600, _nanoseconds: 0 },
  },
  {
    id: "S2BFEwBDyhhXWlaZXSRU",
    open: true,
    content: {
      attachments: {
        auxilaryAttachments: [
          {
            name: "Arena-Guetschi (PDF)",
            type: AttachmentType.FILE,
            urlToResource:
              "https://quino.ch/roxy/app/1s_the_compendium_of_the_arcane/myFile.pdf",
          },
          {
            type: AttachmentType.PICTURE,
            urlToResource:
              "https://quino.ch/roxy/app/1s_the_compendium_of_the_arcane/1.jpg",
          },
          {
            type: AttachmentType.PICTURE,
            urlToResource:
              "https://quino.ch/roxy/app/1s_the_compendium_of_the_arcane/2.jpg",
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/1s_the_compendium_of_the_arcane/3.jpg",
            type: AttachmentType.PICTURE,
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/1s_the_compendium_of_the_arcane/4.jpg",
            type: AttachmentType.PICTURE,
          },
          {
            type: AttachmentType.PICTURE,
            urlToResource:
              "https://quino.ch/roxy/app/1s_the_compendium_of_the_arcane/5.jpg",
          },
          {
            name: "Arena-Guetschi (Wallet File)",
            urlToResource:
              "https://quino.ch/roxy/app/1s_the_compendium_of_the_arcane/myPass.pkpass",
            type: AttachmentType.FILE,
          },
        ],
        displayAttachment: {
          urlToResource:
            "https://quino.ch/roxy/app/1s_the_compendium_of_the_arcane/display.jpg",
          type: AttachmentType.PICTURE,
        },
      },
      title: "29 Jahr Roxy 🥳",
      text: "Hooooi und HAPPY BIIRTHDAY!!! 😊🥳 Schön häsches bis da ane gschafft 😊...Ich hoff es gaht dir guet. Das Jahr gits für dich 🥁 EN APP 🥳 - En advanced advents app, wills scho chli vorher los gaht, cheggsch(?), also in advance höhöhöö...Jedefall es hät au nochli text im hilfe menü, lueg doch det au mal dri, wenns nonig gmacht häsch. Oh...und unde häts denn no was chlises für dich 😊. Ich wünsch dir alles alles gueti zum Geburtstag! Hugs Giusi",
    },
    date: { _seconds: 1632434400, _nanoseconds: 0 },
    title: "It's your birthday!",
  },
  {
    id: "W6gFO8dUasbYikCsVVup",
    open: true,
    content: {
      attachments: {
        displayAttachment: {
          urlToResource:
            "https://quino.ch/roxy/app/4s_the_compendium_of_shadows/display.jpg",
          type: AttachmentType.PICTURE,
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            name: "TBD",
            type: AttachmentType.FILE,
          },
          {
            urlToResource:
              "https://quino.ch/roxy/app/4s_the_compendium_of_shadows/1.jpg",
            type: AttachmentType.PICTURE,
          },
        ],
      },
      title: "TBD",
      text: "TBD",
    },
    date: { _seconds: 1630360800, _nanoseconds: 0 },
    title: "TBD",
  },
  {
    id: "eTWPTX0aYxk7mDfSzPLf",
    open: true,
    content: {
      title: "TBD",
      attachments: {
        displayAttachment: {
          type: AttachmentType.PICTURE,
          urlToResource:
            "https://quino.ch/roxy/app/5s_the_rites_of_benthey/display.jpg",
        },
        auxilaryAttachments: [
          {
            urlToResource:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            type: AttachmentType.FILE,
            name: "TBD",
          },
          {
            type: AttachmentType.PICTURE,
            urlToResource:
              "https://quino.ch/roxy/app/5s_the_rites_of_benthey/1.jpg",
          },
        ],
      },
      text: "TBD",
    },
    title: "TBD",
    date: { _seconds: 1629237600, _nanoseconds: 0 },
  },
];
