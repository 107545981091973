import React from "react";
import { Container } from "../../components/app-shell";
import { Calendar } from "../../components/calendar";
import { EventController } from "../../components/event-controller";

export const CalendarPage: React.FC = () => {
  return (
    <div className="roxy-advent-calendar-page">
      <EventController />
      <Container>
        <Calendar />
      </Container>
    </div>
  );
};
