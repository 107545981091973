import { format } from "date-fns";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import { Button } from "reactstrap";
import { roxyAdventBeApiServiceContext, useEvents } from "../../../api";
import { CommonPath } from "../../utils";
import { InstallPwa } from "../install-pwa";
import { Toast } from "../toast";
import { appShellContext, AppShellContextProvider } from "./app-shell-context";

interface IHeaderProps {}

interface IContentProps {}

interface IContainerProps {}

interface IFootertProps {}

interface IAppShellProps {}

export const Header: React.FC<IHeaderProps> = ({ children }) => {
  const { currentUser } = useContext(appShellContext);

  if (!!!currentUser) {
    return null;
  }

  return (
    <div
      data-testid="app-shell-header"
      className="roxy-advent-app-shell__header"
    >
      {children}
    </div>
  );
};

export const Content: React.FC<IContentProps> = ({ children }) => {
  return (
    <div
      data-testid="app-shell-content"
      className="roxy-advent-app-shell__content"
    >
      {children}
    </div>
  );
};

export const Container: React.FC<IContainerProps> = ({ children }) => {
  return (
    <div
      data-testid="app-shell-content-container"
      className="roxy-advent-app-shell-container"
    >
      {children}
    </div>
  );
};

export const Footer: React.FC<IFootertProps> = ({ children }) => {
  const { currentUser } = useContext(appShellContext);

  if (!!!currentUser) {
    return null;
  }

  return (
    <div
      data-testid="app-shell-footer"
      className="roxy-advent-app-shell__footer"
    >
      <div className="roxy-advent-app-shell__footer--children"> {children}</div>
    </div>
  );
};

export interface IEventToastProps {
  openLink: (id: string) => void;
}

export const EventToast: React.FC<IEventToastProps> = ({ openLink }) => {
  const { data: events, isLoading } = useEvents();

  if (isLoading) {
    return null;
  }

  return (
    <div className="roxy-advent-app-shell__toasts">
      {events
        ?.filter(
          (event) =>
            new Date(format(event.date._seconds * 1000, "MM/dd/yyyy")) <
              new Date() &&
            format(Date.now(), "MM/dd/yyyy") !==
              format(event.date._seconds * 1000, "MM/dd/yyy")
        )
        .map((event) => (
          <Toast
            className="roxy-advent-app-shell__toast-body"
            key={event.id}
            showToast={!event.open}
            title="Special Event"
            text={`Du häsch en Event verpasst: ${event.title}`}
            openLink={() => openLink(event.id)}
          >
            <div className="roxy-advent-app-shell__toast-content">
              <div className="roxy-advent-app-shell__toast-content--notice">
                <h1 className="h6">Special Day :)</h1>
                <small className="text-muted">
                  Es hät en neue Special Day: {event.title}
                </small>
              </div>
              <Button
                className="roxy-advent-app-shell__toast-content--action"
                size="sm"
                color="link"
                onClick={() => openLink(event.id)}
              >
                Zeigmal!
              </Button>
            </div>
          </Toast>
        ))}
    </div>
  );
};

export const AppShell: React.FC<IAppShellProps> = ({ children }) => {
  const { fireBaseApp } = useContext(roxyAdventBeApiServiceContext);
  const currentUser = fireBaseApp.auth().currentUser;

  const history = useHistory();

  const openLink = (id: string) => {
    history.replace(
      generatePath(CommonPath.EVENT, {
        eventId: id,
      })
    );
  };

  return (
    <AppShellContextProvider value={{}}>
      <InstallPwa />
      <div data-testid="app-shell" className="roxy-advent-app-shell">
        {currentUser !== undefined && <EventToast openLink={openLink} />}
        {children}
      </div>
    </AppShellContextProvider>
  );
};
