import React, { useEffect, useRef } from "react";
import { uniqueId } from "lodash";
import type { Options as IPlyrSettings } from "plyr";
import Plyr from "plyr";
import { PlyrControls } from "./plyr-api";
import type { IPlyrTranslation } from "./translation";
import { de } from "./translation";

interface IPlyrPlayerProps {
  src: string;
  mediaType?: string;
  className?: string;
  playsInline?: boolean;
  controls?: boolean;
  settings?: IPlyrSettings;
  isCurrent?: boolean;
  onLoadedData?: (e?: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
}

const playerTranslations: Record<string, IPlyrTranslation> = {
  de: de,
};

export const PlyrPlayer: React.FC<IPlyrPlayerProps> = (props) => {
  const playerId: string = uniqueId("plyr_");

  const player = useRef<Plyr | null>(null);

  useEffect(() => {
    // Initializes the player and sets the default player language-pack based on the selected i18n language of the application
    const defaultSettings: IPlyrSettings = {
      controls: [
        PlyrControls.play,
        PlyrControls.progress,
        PlyrControls.currentTime,
        PlyrControls.mute,
        PlyrControls.volume,
        PlyrControls.fullscreen,
      ],
      i18n: playerTranslations["de"],
    };
    const settings: IPlyrSettings = props.settings
      ? { ...defaultSettings, ...props.settings }
      : defaultSettings;

    if (player.current == null) {
      player.current = new Plyr(`#${playerId}`, settings);
    }

    return () => {
      if (player) {
        player.current?.destroy();
      }
    };
  }, [playerId, props.settings]);

  useEffect(() => {
    if (player !== null && player.current?.playing && !props.isCurrent) {
      player?.current.pause();
    }
  });

  return (
    <video
      id={playerId}
      className={props.className}
      playsInline={props.playsInline}
      controls={props.controls ? props.controls : true}
      onLoadedData={props.onLoadedData}
      onLoadedMetadata={props.onLoadedData}
      preload="auto"
    >
      <source src={props.src} type={props.mediaType} />
    </video>
  );
};
