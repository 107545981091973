import { useContext } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { roxyAdventBeApiServiceContext } from "../../../api-service-context";
import { IEvent } from "../../domain";
import { QueryKeyEntityType } from "../../query-keys";

export const useEvents = (): UseQueryResult<IEvent[], Error> => {
  const { roxyAdventBeApi } = useContext(roxyAdventBeApiServiceContext);
  const fetchEvents = () => roxyAdventBeApi.fetchEvents();

  return useQuery(QueryKeyEntityType.ROXY_ADVENT_EVENTS, fetchEvents, {
    refetchOnWindowFocus: false,
  });
};
