import React, { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { appShellContext } from "../components/app-shell";
import { CommonPath } from "./common-path";

export interface IPrivateRouteProps extends RouteProps {
  component: React.FC<any>;
}

export const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  component: RouteComponent,
  ...rest
}) => {
  const { currentUser } = useContext(appShellContext);

  return (
    <>
      <Route
        {...rest}
        render={(routeProps) =>
          !!currentUser ? (
            <RouteComponent {...routeProps} />
          ) : (
            <Redirect to={CommonPath.ROOT} />
          )
        }
      />
    </>
  );
};
