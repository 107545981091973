import React from "react";
import { Modal, Card, CardBody, CardText, CardTitle, Button } from "reactstrap";
import logo from "../../../assets/logo-icon.svg";
import addToHomeScreen from "../../../assets/icons/add-to-homescreen.svg";
import useIosInstallPrompt from "../../hooks/use-ios-install-prompt";
import useWebInstallPrompt from "../../hooks/use-web-install-prompt";

interface IInstallPwaProps {}

export const InstallPwa: React.FC<IInstallPwaProps> = () => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }

  return (
    <Modal isOpen={true} centered={true}>
      <Card>
        <div className="mx-auto roxy-advent-install-pwa-logo">
          <img src={logo} style={{ width: "50px" }} alt="logo" />
        </div>
        <CardBody>
          <CardTitle className="text-center">
            <h3>Häsch gwüsst...</h3>
          </CardTitle>
          {iosInstallPrompt && (
            <>
              <CardText className="text-center">
                Die Siete chasch du ganz eifach au als Mobile-App installiere.
                Tap unde uf
                <img
                  src={addToHomeScreen}
                  style={{ margin: "auto 8px 8px" }}
                  className=""
                  alt="Add to homescreen"
                  width="20"
                />
                denn &quot;Add to Home Screen&quot; oder wies au immer uf dütsch
                heisst 😁...
              </CardText>
              <div className="d-flex justify-content-center">
                <Button onClick={handleIOSInstallDeclined}>Close</Button>
              </div>
            </>
          )}
          {webInstallPrompt && (
            <>
              <CardText className="text-center">
                Die Siete chasch du ganz eifach au als Mobile-App installiere.
                Es machts sicher eifacher zum druff zuegriff, probiers doch us
                😊.
              </CardText>
              <div className="d-flex justify-content-around">
                <Button color="primary" onClick={handleWebInstallAccepted}>
                  Installiere
                </Button>
                <Button onClick={handleWebInstallDeclined}>
                  Vielicht spöter...
                </Button>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
};
